<template>
  <div class="d-flex justify-content-center">
    <b-row :class="`mt-3 ${has_slug ? 'col-12 col-sm-10' : 'col-12 col-lg-8 col-xl-5'}`">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ $t('creator.attachFile') }}</h1>
        <p>
          {{$t('creator.attachmentsText')}}
        </p>

        <b-button-group class="w-100 mt-1 mb-2">
          <b-button 
            @click="tabs_options = 0" 
            :class="{'active': tabs_options === 0}" 
            class="button-tabs-options mr-1" 
            variant="button-tabs-options"
            
          >
            {{ $t('creator.files') }}
            <span v-if="files.length > 0">({{ files.length }})</span>
          </b-button>
          <b-button 
            @click="tabs_options = 1" 
            :class="{'active': tabs_options === 1}" 
            class="button-tabs-options" 
            variant="button-tabs-options"
          >
            Links 
            <span v-if="links.length > 0 ">({{ links.length }})</span>
          </b-button>
        </b-button-group>

        <div v-if="tabs_options === 0">
          <div>
            <b-form-file 
              v-model="file" 
              accept=".jpeg, .jpg, .png, .mp4, .mov, .pdf, .docx, .xlsx, .pptx, .csv, .txt, .rtf"
              browse-text="Buscar" 
              class="my-2" 
              @input="hasChangeFiles"
              :placeholder="$t('creator.chooseFileFiles')"
            >
            </b-form-file>
          </div>
          <b-col v-for="(file, index) in files" :key="index" class="col-12 p-0 py-2 border-bottom d-flex flex-wrap justify-content-between">
            <div class="d-flex align-items-center">
              <b-avatar v-if="!file.uuid" :key="update_avatar" :src="imgOrvid(file.preview.preview)" :text="txtExt(file.preview.preview)" square class="avatar-preview-sample"></b-avatar>
              <div v-else>
                <b-avatar v-if="!file.preview.preview.includes('.mp4')" :key="update_avatar" :src="imgOrvid(file.preview.preview)" :text="txtExt(file.preview.preview)" square class="avatar-preview-sample"></b-avatar>
                <video v-else :src="file.preview.preview" class="avatar-preview-sample"></video>
              </div>
              <strong v-if="!file.uuid">
                <span v-if="file.preview.name.length > 0">
                    {{shortText(5, file.preview.name)}} <span v-if="file.preview.name.split(' ').length > 6">...</span>
                </span>
                <span v-else class="text-muted">{{ $t('creator.noCaption') }}</span>
              </strong>
              <strong v-else class="text-muted">{{ $t('creator.upFile') }}</strong>
            </div>
            <b-button variant="flat-secondary" @click="deleteFromList(file)">
              <feather-icon icon="TrashIcon" size="20"></feather-icon>
            </b-button>
  
            <b-dropdown class="w-100 mt-1" menu-class="w-100" variant="flat-secondary">
              <template #button-content>
                {{file.asset_type ? file.asset_type.text : $t('creator.assetType')}}
              </template>
              <b-dropdown-item v-for="(item) in $t('creator.asset_type')" :key="item.value" @click="addAssetType(file, item)">
                <div class="d-flex align-items-center text-wrap">
                  <div class="d-flex">
                    <b-icon :icon="item.icon" class="mr-05"></b-icon>
                    <div>
                      <strong>{{item.text}}</strong>
                      <p class="avenir-medium m-0">{{item.description}}</p>
                    </div>
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </div>

        <div v-else>
          <step-attachment-links 
            :links="links"
            @delete_link="deleteFromListLink"
            @update_button_save="update_button_save = !update_button_save"
          ></step-attachment-links>
        </div>
        <b-col class="col-12 d-flex justify-content-center flex-wrap">
          <b-button 
            variant="blue-button-next-step" 
            class="blue-button-next-step my-2" 
            @click.once="save()"
            :disabled="!disabledButton() || loading_service"
            :key="update_button_save"
          > 
            <div v-if="loading_service">
              <b-spinner class="spinner-waiting-service"></b-spinner>
              {{ $t('creator.upLoading') }}
            </div>
            <div v-else-if="!has_slug">
              {{ $t('creator.saveStep') }}
            </div>
            <div v-else>
              {{ $t('creator.savePreview') }}
            </div>
          </b-button>
          <small class="mt-2 w-100 text-center text-muted">{{ $t('creator.attachOptional') }}</small>

        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormFile,
  BAvatar,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BButtonGroup,
} from 'bootstrap-vue';
import { getThumbnails } from 'video-metadata-thumbnails';
import { getUniqueIndex } from '@/libs/utils/others'
import { getAssetUrl, urlStateExpression } from '@/libs/utils/urls'
import { shortText } from '@/libs/utils/formats';
import casting_calls_services from '@/services/casting_calls';

export default {
  name: 'stepAttachmentFiles',
  components: {
    BRow,
    BCol,
    BButton,
    BFormFile,
    BAvatar,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BButtonGroup,
    stepAttachmentLinks: () => import('./stepAttachmentLinks.vue')
  },
  data() {
    return {
      shortText,
      getUniqueIndex,
      getAssetUrl,
      urlStateExpression,
      doc_ext: [".pdf", ".docx", ".xlsx", ".pptx", ".csv", ".txt", ".rtf"],
      files: [],
      steps: {},
      loading_service: false,
      file: null,
      assets_types: [],
      update_avatar: false,
      casting_call_uuid: '',
      casting_call: {},
      update_button_save: false,
      tabs_options: 0,
      links: [],
    }
  },
  created() {
    this.getData();
  },
  computed: {
    has_slug() {
      return this.$route.params.slug
    }
  },
  methods: {
    deleteFromListLink(link) {
      if (link.uuid) {
        casting_calls_services.deleteLink(this.casting_call_uuid, link.uuid).then(() => {          
          this.links = this.links.filter((f) => f.uuid !== link.uuid);
        });
      } else {
        this.links = this.links.filter((f) => f.id !== link.id);
      }
    },
    disabledButton() {
      let counter_link = 0;
      this.links.forEach((link) => {
        if (urlStateExpression(link.link)) counter_link += 1;
      })
      return (counter_link === this.links.length) && (!this.isAllTypesUsed())
    },
    
    imgOrvid(filename) {
      if (filename.includes(this.doc_ext)) {
        return '';
      } else {
        return filename;
      }
    },
    txtExt(filename) {
      return this.doc_ext.find(ext => filename.includes(ext)) || "";
    },
    isAllTypesUsed() {
      let counter_asset_types = 0;
      this.files.forEach((f) => {
        if (f.asset_type) counter_asset_types += 1;
      })
      this.links.forEach((f) => {
        if (f.type_link) counter_asset_types += 1;
      })
      return counter_asset_types !== (this.files.length + this.links.length)
    },
    addAssetType(file, asset_type) {
      file['asset_type'] = asset_type;
      this.update_button_save = !this.update_button_save;
    },
    hasChangeFiles() {
      setTimeout(() => {
        if (this.file) {
          const url_blob = URL.createObjectURL(this.file);
          const unique_index = getUniqueIndex()
          this.file['id'] = unique_index;
          if (this.file.type.includes('image')) {
            this.file['preview'] = {preview: url_blob, name: this.file.name};
            this.files.push(this.file)
            this.file = null;
          } else if (this.file.type.includes('video')) {
            this.file['preview'] = {preview: 'is_video', name: this.file.name};
            this.files.push(this.file)
            const thumbnail = getThumbnails(url_blob, {
              quality: 0.6
            });
            thumbnail.then((value) => {
              const url_blob_thubnail = URL.createObjectURL(value[0].blob);
              this.files.map((f) => {
                if (f.id === unique_index) {
                  f.preview.preview = url_blob_thubnail
                  this.update_avatar = !this.update_avatar;
                  this.file = null;
                }
              });
            });
          } else {
            this.file['preview'] = {preview: this.file.name, name: this.file.name};
            this.files.push(this.file)
            this.file = null;
          }
        }
      }, 200)
    },
    getData() {
      if (this.has_slug) {
        this.casting_call = Object.assign({}, this.$route.params.casting_call_data)
        this.casting_call_uuid = this.casting_call.uuid;
        this.getFormatUploadedFiles();
        if (this.casting_call.links.length > 0) this.links = this.casting_call.links.slice();
      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        this.casting_call_uuid = this.steps.uuid ? this.steps.uuid : this.$route.params.casting_call_uuid
        casting_calls_services.getCastingCall(this.casting_call_uuid).then((response) => {
          this.casting_call = response;
          this.getFormatUploadedFiles();
          if (this.casting_call.links.length > 0) this.links = this.casting_call.links.slice();
        });
      }

    },
    getFormatUploadedFiles() {
      this.casting_call.files.forEach((file) => {
        this.files.push({
          preview: {
            preview: file.file ? getAssetUrl(file.file) : file.asset_url,
          },
          uuid: file.uuid,
          asset_type: this.$t('creator.asset_type').find((asset) => asset.value === file.type_file),
        });
      });
    },
    includeFormatImage(file) {
      if (file.includes('.jpeg') ||
      file.includes('.png') ||
      file.includes('.jpg')) {
        return true
      } else return false
    },
    deleteFromList(file) {
      if (file.uuid) {
        casting_calls_services.deleteFile(this.casting_call_uuid, {files_uuid: [file.uuid]}).then(() => {
          this.files = this.files.filter((f) => f.uuid !== file.uuid);
        });
      } else {
        this.files = this.files.filter((f) => f.id !== file.id);
      }
    },
    save() {
      if (!this.isAllTypesUsed()) {
        const form_data = new FormData();
        const files_to_create = this.files.filter((file) => file.id);
        const files_to_update = this.files.filter((file) => file.uuid).map((f) => {
          return {
            uuid: f.uuid,
            type_file: f.asset_type.value,
          }
        });
        files_to_create.forEach((file) => {
          form_data.append('files', file);
          form_data.append('type_files', file.asset_type.value)
        });
        this.loading_service = true;
        this.saveLinks();
        casting_calls_services.attatchFiles(this.casting_call_uuid, form_data).then((response_create) => {
          if (files_to_create.length > 0) this.$emit('update_campaign', response_create);
          casting_calls_services.updateFiles(this.casting_call_uuid, {files: files_to_update}).then((response_update) => {
            if (!this.has_slug) this.$router.push({ name: 'steps_casting_call', params: {step: 'compensation'}})
            else this.$emit('update_campaign', response_update);
            this.loading_service = false;
          });
        });
      }
    },
    saveLinks() {
      const links_to_create = this.links.filter((link) => link.id);
      const links_to_update = this.links.filter((link) => link.uuid);
      casting_calls_services.attatchLinks(this.casting_call_uuid, {links: links_to_create}).then(() => {
        casting_calls_services.updateLinks(this.casting_call_uuid, {links: links_to_update}).then(() => {
        
        });
      });
    },
  },
}
</script>
<style scoped lang="scss">
.button-tabs-options {
  background: #e9ecef !important;
  border-radius: 0.7em !important;
  padding: 1.5em !important;

  &:hover  {
    background: #dee2e6 !important;

    &:disabled {
      background: #e9ecef !important;
    }
  }

  &.active {
    background-color: #54575a !important;
    color: white !important
  }
}
.text-wrap {
  flex-flow: wrap;
}
.spinner-waiting-service {
  width: 18px;
  height: 18px;
}
.avatar-preview-sample {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 1em !important;
  margin: 0 1em 0 0;
}
.mr-05 {
  margin-right: 0.5em;
}
</style>